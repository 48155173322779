//@ts-check
import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import styles from './article-preview.module.css'

export default ({ article }) => (
  <div className={styles.preview}>

    <div className={styles.imageDiv}>
      <Link to={`/blog/${article.slug}`}>
        <Img alt="Image alt" sizes={article.heroImage.fluid} style={{minHeight: "200px"}}/>
      </Link>
    </div>

    <Link className={styles.title} to={`/blog/${article.slug}`}>
      <h3>
        {article.title}
      </h3>
    </Link>
    <p
      dangerouslySetInnerHTML={{
        __html: article.description.childMarkdownRemark.html,
      }}
    />
    <p className={styles.small}>{article.publishDate}</p>
  </div>
)
