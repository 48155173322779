//@ts-check
import React from 'react'
import Link from 'gatsby-link'
import Img from 'gatsby-image'

import styles from './most-recent.module.css'


export default ({ article }) => (
  <div className={styles.preview}>

    <div className={styles.col1}>
      <div className={styles.imageDiv}>
        <Link to={`/blog/${article.slug}`}>
          <Img alt="Image" sizes={article.heroImage.fluid} />
        </Link>
      </div>
    </div>

    <div className={styles.col2}>
      <Link className={styles.title} to={`/blog/${article.slug}`}>
        <h2 className={styles.heading}>
          {article.title}
        </h2>
      </Link>
      <p
        dangerouslySetInnerHTML={{
          __html: article.description.childMarkdownRemark.html,
        }}
      />

      <p className={styles.small}>{article.publishDate}</p>
    </div>

  </div>
)
