//@ts-check
import React from 'react'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'

// Pages
import MostRecent from '../components/most-recent'
import ArticlePreview from '../components/article-preview'

// icons
import favicon from '../assets/images/favicon.ico';
import { graphql } from 'gatsby'
import Layout from '../components/Layout'

import { tagManager, bodyTagManager } from '../scripts';

class BlogIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')

    const mostRecent = posts.length > 0 ? posts[0].node : null;
    const morePosts = posts.slice(1);

    return (
      <Layout location={this.props.location}>

        <Helmet title={`Blog de ScreenFox`} >

          <html lang="es" />
          <link rel="alternate" href={`https://getscreenfox.com${this.props.location.pathname}`} hrefLang="es" />
          <link rel="icon" href={favicon} type="image/x-icon" />

          <meta name="description" content={`El mejor contenido de digital signage en español`} />
          <meta property="og:title" content={`Blog de ScreenFox`} />
          <meta property="og:description" content={`El mejor contenido de digital signage en español.`} />

          {/* TODO: Add image */}
          {/* <meta property="og:image" content={``} /> */}

          <script>{tagManager}</script>
        </Helmet>

        <section>
          <div className="container">
            <div style={{ padding: '100px 0px' }}>

              <div
                dangerouslySetInnerHTML={{
                  __html: bodyTagManager,
                }}></div>

              <div>

                <h1>Blog de ScreenFox</h1>

                <div>
                  <MostRecent article={mostRecent} />
                </div>

                <ul className="article-list">
                  {morePosts.map(({ node }) => {
                    return (
                      <li key={node.slug} style={{ marginTop: "60px" }} >
                        <ArticlePreview article={node} />
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>

          </div>
        </section>

      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 700, maxHeight: 392, quality: 100) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
